import React, { useState, useEffect } from "react";
import RepoList from "./components/RepoList";

function App() {

  const [repoList, setRepoList] = useState<[]>();


  useEffect(() => {
    getRepos();
  }, []);

  const getRepos = async () => {
    const response = await fetch('https://api.github.com/users/archivine/repos');
    const data = await response.json();
    setRepoList(data);
  }

  return (
    <div className="App">
      <RepoList repos={repoList} />
    </div>
  );
}

export default App;
