import styles from "../styles/RepoList.module.css";


const RepoList = (props: any) => {

    let repos: [] = props.repos;

    return (
        <table className={styles.table}>
            <h2>Index of / </h2>
            <tbody>
                <tr className={styles.head}>

                    <td>Name</td>
                    <td>Created</td>
                    <td>Last updated</td>
                </tr>
                {repos ? (
                    <>
                        {repos.map((repo) => {
                            return (
                                <tr key={repo['id']}>
                                    <td key={repo['name']} className={styles.name}><a href={repo['html_url']}>{repo['name']}</a></td>
                                    <td key={repo['created_at']}>{new Date(repo['created_at']).toLocaleString()}</td>
                                    <td key={repo['updated_at']}>{new Date(repo['updated_at']).toLocaleString()}</td>
                                </tr>
                            );
                        })}
                    </>
                ) : (
                    <tr><td>loading...</td></tr>
                )}
            </tbody>
        </table>
    )
}

export default RepoList;
